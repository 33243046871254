<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Rekap Pohon Perjalan </h4>
            
            <span style="float:right">
              <span class="float-left" style='margin-right:10px'>
                <CDropdown size="sm" color="success " placement="bottom-end">
                  <template #toggler-content> Filter Jalan </template>
                    <CDropdownItem @click="fetchData(jalan)" v-for="jalan in jalans" :key="jalan">{{jalan}}</CDropdownItem>
                </CDropdown>               
              </span> 
              <CButton color="dark" @click="exportToExcel" size="sm" style='margin-right:10px'>Excel</CButton>
              <CButton color="danger" size="sm" @click="exportToPDF">PDF</CButton>              
            </span>
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table class="table table-bordered" id="" ref="table" :options="{ deletePohon }">
          <thead>
            <tr>
              <th style="width:35%">Alamat</th>
              <th>Kategori Pohon</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
              <template v-for="(addresses, address) in pohons" >
                <!-- First row with rowspan for Kategori Pohon -->
                <tr :key="address">
                  <td :rowspan="addresses.length">{{ address }}</td>
                  <td>{{ addresses[0].name }}</td>
                  <td>{{ addresses[0].jumlah_pohon }} Pohon</td>
                </tr>

                <!-- Remaining rows for the same Kategori Pohon -->
                <tr v-for="(item, index) in addresses.slice(1)" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.jumlah_pohon }} Pohon</td>
                </tr>
              </template>
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>

    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deletePohon()" color="danger">Hapus</CButton>
      </template>
    </CModal>

  </div>
</template>
<style lang="css" scoped >
/* @import "~material-design-icons-iconfont/dist/material-design-icons"; */
</style>
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';  // Use named imports for 'xlsx'

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
  },
  data: function() {
        return {
            jalans: [],
            pohons: [],
            deleteModal : false,
            idselected:"0",
            products:[],
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    exportToPDF() {
      console.log('')
      const element = this.$refs.table; // Reference to the HTML element (table)
      html2pdf(element);
    },
    exportToExcel() {
      console.log('')
      const ws = XLSX.utils.table_to_sheet(this.$refs.table); // Reference to the HTML table
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'exported-table.xlsx');
    },
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    deletePohon: function(){
      // alert(this.idselected);
      axios.get(process.env.VUE_APP_BASE_URL+"pohon/"+this.idselected)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    fetchData: function(jalan){
      axios.get(process.env.VUE_APP_BASE_URL+"rekappohon?jalan="+jalan)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status===200){
              this.pohons = response.data
              console.log(this.pohons)
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {
      

      axios.get(process.env.VUE_APP_BASE_URL+"rekappohon")
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status===200){
              this.pohons = response.data
              console.log(this.pohons)
            }
            else{
              this.myError = true;
            }
            
        })

        axios.get(process.env.VUE_APP_BASE_URL+"jalans")
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status===200){
              this.jalans = response.data
              console.log(this.jalans)
            }
            else{
              this.myError = true;
            }
            
        })
      
    }
  }
}
</script>